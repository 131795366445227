import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LogoutComponent.module.css';
import { logout } from '../../services/auth';
import { REDIRECT_PATH } from '../../constants/constants';
import { resetClientState } from '../../reducers/clientReducer';
import { resetEmergencyState } from '../../reducers/emergencyReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logoutManually } from '../../services/ampolitudeTracker';
import { RootState } from '../../store/store';

const LogoutComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.client);
  const { emergency } = useSelector((state: RootState) => state.emergency);

  const handleLogout = () => {
    dispatch(resetClientState());
    dispatch(resetEmergencyState());
    logoutManually(user, emergency);
    logout();
    navigate(REDIRECT_PATH);
  };

  return (
    <button className={styles['logout-btn']} onClick={handleLogout}>
      <i className="fas fa-sign-out-alt"></i>
      <span className={styles['tooltip-text']}>{t('exitFromPanic')}</span>
    </button>
  );
};

export default LogoutComponent;
