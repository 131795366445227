import React from 'react';
import { useFile } from './file';
import LoadingComponent from '../components/Loading/LoadingComponent';

interface PhotoProps {
  photoId: string;
  alt: string;
  onClick?: () => void;
  className?: string;
}

const Photo: React.FC<PhotoProps> = ({ photoId, alt, onClick, className }) => {
  const photoUrl = photoId
    ? `${process.env.REACT_APP_ENDPOINT}/storage/file/emergency/${photoId}`
    : undefined;

  const file = useFile(photoUrl);

  return file ? (
    <img
      src={file}
      alt={alt}
      className={className}
      onClick={onClick}
      sizes={'200'}
    />
  ) : (
    <LoadingComponent />
  );
};

export default Photo;
