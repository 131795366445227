import * as amplitude from '@amplitude/analytics-browser';
import { getCode } from './auth';
import { getDeviceId, getUserIP } from '../utils/device';
import { Client, Emergency } from '../types/types';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

if (AMPLITUDE_API_KEY) {
  amplitude.init(AMPLITUDE_API_KEY, {
    autocapture: true,
  });
} else {
  console.error('Amplitude API Key is missing.');
}

interface GeneralParams {
  PanicID: number | undefined;
  Code: string | undefined;
  CodeType: 'openCode' | 'closedCode' | undefined;
  UserID: number | undefined;
  UserPhone: string | undefined;
  IP: string | null;
  DeviceID: string | null;
}

const trackEvent = async (
  eventName: string,
  additionalParams: Partial<GeneralParams> & { error?: string } = {},
  user?: Client | null,
  emergency?: Emergency | null
) => {
  const code = getCode();
  const generalParams: GeneralParams = {
    PanicID: emergency?.id,
    Code:
      code === emergency?.openCode
        ? emergency?.openCode
        : emergency?.closedCode,
    CodeType: code
      ? code === emergency?.openCode
        ? 'openCode'
        : 'closedCode'
      : undefined,
    UserID: emergency?.userId,
    UserPhone: user?.phone,
    IP: await getUserIP(),
    DeviceID: getDeviceId(),
  };

  amplitude.track(eventName, { ...generalParams, ...additionalParams });
};

export const loginCodeSuccess = (
  user: Client | null,
  emergency: Emergency | undefined
) => {
  trackEvent('login_code_success', undefined, user, emergency);
};

export const loginCodeFail = (error: string) => {
  trackEvent('login_code_fail', {
    error: error,
  });
};

export const logoutManually = (
  user: Client | null,
  emergency: Emergency | null
) => {
  trackEvent('logout_manually', undefined, user, emergency);
};

export const logoutClosed = (
  user: Client | null,
  emergency: Emergency | null
) => {
  trackEvent('logout_closed', undefined, user, emergency);
};

export const logoutForce = (
  user: Client | null,
  emergency: Emergency | null,
  error: string
) => {
  trackEvent(
    'logout_force',
    {
      error: error,
    },
    user,
    emergency
  );
};

export const languageChangeRo = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('language_change_ro', undefined, user, emergency);
};

export const languageChangeRu = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('language_change_ru', undefined, user, emergency);
};

export const languageChangeEn = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('language_change_en', undefined, user, emergency);
};

export const mapFullScreenOpen = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('map_full_screen_open', undefined, user, emergency);
};

export const mapFullScreenClose = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('map_full_screen_close', undefined, user, emergency);
};

export const mapSatellite = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('map_satellite', undefined, user, emergency);
};

export const mapRegular = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('map_regular', undefined, user, emergency);
};

export const closeEmergencySuccess = (
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent('close_emergency_success', undefined, user, emergency);
};

export const closeEmergencyFail = (
  error: string,
  user?: Client | null,
  emergency?: Emergency | null
) => {
  trackEvent(
    'close_emergency_fail',
    {
      error: error,
    },
    user,
    emergency
  );
};
