import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PanicComponent.module.css';
import { getCode } from '../../services/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import moment from 'moment';

const PanicScreen: React.FC = () => {
  const { t } = useTranslation();
  const { emergency } = useSelector((state: RootState) => state.emergency);
  const code = getCode();

  return (
    <div className={styles['panic-screen']}>
      <div className={styles['top-left']}>
        <h2>
          {t('insertedCode')}: {code}
        </h2>
      </div>
      <div className={styles['top-right']}>
        <h3>{t(emergency?.status === 'open' ? 'Open' : 'Closed')}</h3>
        <p>
          {t('startDateTime')}:{' '}
          {emergency &&
            moment(emergency.createdAt).format('DD/MM/YYYY, HH:mm:ss')}
        </p>
        {emergency?.closedAt && (
          <p>
            {t('closeDateTime')}:{' '}
            {moment(emergency.closedAt).format('DD/MM/YYYY, HH:mm:ss')}
          </p>
        )}
      </div>
    </div>
  );
};

export default PanicScreen;
