import axios from 'axios';
import { Client } from '../types/types';
import { ApiResponse, handleRequest } from '../utils/errorHandling';

const API_BASE_URL = process.env.REACT_APP_ENDPOINT;
export const ClientService = {
  getClient: async (id: string): Promise<ApiResponse<Client>> => {
    return handleRequest<Client>(async () => {
      return await axios.get(`${API_BASE_URL}/emergency/details/${id}`);
    });
  },
};
