import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginPage.module.css';
import { useTranslation } from 'react-i18next';
import { getCode, login } from '../../services/auth';
import { EmergencyService } from '../../services/emergency';
import { setEmergencyData } from '../../reducers/emergencyReducer';
import { useDispatch } from 'react-redux';
import {
  loginCodeFail,
  loginCodeSuccess,
} from '../../services/ampolitudeTracker';
import { ClientService } from '../../services/client';
import { setClientData } from '../../reducers/clientReducer';

const LoginPage = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCode()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleCodeSubmit = async () => {
    const response = await EmergencyService.getEmergency(code);
    const user = await ClientService.getClient(code);
    if (response.data && user.data) {
      dispatch(setEmergencyData(response.data));
      dispatch(setClientData(user.data));
      login(code);
      loginCodeSuccess(user.data, response.data);
      navigate('/dashboard');
    } else if (response.error) {
      loginCodeFail(response.error.message);
      setError(t('wrongCode'));
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleCodeSubmit();
    }
  };

  return (
    <div className={styles['body-fixed']}>
      <div className={styles['center-container']}>
        <div className={styles['code-input-container']}>
          <h1>{t('enterCode')}</h1>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <button onClick={handleCodeSubmit}>{t('submit')}</button>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
