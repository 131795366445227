import axios from 'axios';
import { ApiResponse, handleRequest } from '../utils/errorHandling';
import { Emergency, EmergencyLocation } from '../types/types';
const API_BASE_URL = process.env.REACT_APP_ENDPOINT;
export const EmergencyService = {
  /**
   * Fetches emergency details by ID.
   * @param {string} id - The ID of the emergency.
   * @returns {Promise<ApiResponse<Emergency>>} - The response data or error.
   */
  getEmergency: async (id: string): Promise<ApiResponse<Emergency>> => {
    return handleRequest<Emergency>(async () => {
      return await axios.get(`${API_BASE_URL}/emergency/${id}`);
    });
  },

  /**
   * Fetches emergency location by ID.
   * @param {string} id - The ID of the emergency.
   * @returns {Promise<ApiResponse<EmergencyLocation>>} - The response data or error.
   */
  getEmergencyLocation: async (
    id: string
  ): Promise<ApiResponse<EmergencyLocation>> => {
    return handleRequest<EmergencyLocation>(async () => {
      return await axios.get(`${API_BASE_URL}/emergency/location/${id}`);
    });
  },

  /**
   * Ends an emergency with specified latitude and longitude.
   * @param {string} id - The ID of the emergency.
   * @param {number} lat - The latitude of the end location.
   * @param {number} lon - The longitude of the end location.
   * @returns {Promise<ApiResponse<void>>} - The response data or error.
   */
  endEmergency: async (
    id: string,
    lat: number,
    lon: number
  ): Promise<ApiResponse<void>> => {
    return handleRequest<void>(async () => {
      return await axios.delete(`${API_BASE_URL}/emergency/${id}`, {
        data: {
          lat,
          lon,
        },
      });
    });
  },
};
