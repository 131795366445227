import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { handleRequest } from './errorHandling';
import {
  IP_CACHE_DURATION,
  IP_CACHE_KEY,
  IP_CACHE_TIMESTAMP_KEY,
} from '../constants/constants';

export const getDeviceId = () => {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
};

export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
  );
};
export const getUserIP = async (): Promise<string | null> => {
  const cachedIp = localStorage.getItem(IP_CACHE_KEY);
  const cachedTimestamp = localStorage.getItem(IP_CACHE_TIMESTAMP_KEY);
  const currentTime = Date.now();

  if (
    cachedIp &&
    cachedTimestamp &&
    currentTime - parseInt(cachedTimestamp) < IP_CACHE_DURATION
  ) {
    return cachedIp;
  }

  const response = await handleRequest<any>(async () => {
    return await axios.get('https://api.ipify.org?format=json');
  });
  if (response.data) {
    const ip = response.data.ip;

    localStorage.setItem(IP_CACHE_KEY, ip);
    localStorage.setItem(IP_CACHE_TIMESTAMP_KEY, currentTime.toString());

    return ip;
  }
  return null;
};
