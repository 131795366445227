import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../types/types';

interface ClientState {
  user: Client | null;
  loading: boolean;
  error: string | null;
}

const initialState: ClientState = {
  user: null,
  loading: false,
  error: null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    fetchClientStart(state) {
      state.loading = true;
      state.error = null;
    },
    setClientData(state, action: PayloadAction<Client>) {
      state.user = action.payload;
      state.loading = false;
    },
    fetchClientError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetClientState: () => initialState,
  },
});

export const {
  fetchClientStart,
  setClientData,
  fetchClientError,
  resetClientState,
} = clientSlice.actions;

export default clientSlice.reducer;
