import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEmergencyError,
  resetEmergencyState,
  updateEmergencyLocation,
} from '../reducers/emergencyReducer';
import { EmergencyService } from '../services/emergency';
import { getCode, isAuthenticated, logout } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import { REDIRECT_PATH } from '../constants/constants';
import { resetClientState } from '../reducers/clientReducer';
import { RootState } from '../store/store';
import { useEffect, useRef } from 'react';

const useFetchEmergencyLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emergency } = useSelector((state: RootState) => state.emergency);
  const code = getCode();

  const fetchEmergencyLocation = async () => {
    if (emergency?.status === 'closed') {
      clearWorkerInterval();
    }

    if (!isAuthenticated() || !code) {
      clearWorkerInterval();
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      navigate(REDIRECT_PATH);
      return;
    }

    const response = await EmergencyService.getEmergencyLocation(code);

    if (response.data) {
      dispatch(updateEmergencyLocation(response.data));
    } else if (response.error) {
      dispatch(fetchEmergencyError(response.error.message));
      clearWorkerInterval();
    }
  };

  const workerRef = useRef<Worker | null>(null);

  const startWorker = () => {
    if (workerRef.current || !navigator.onLine) return;

    workerRef.current = new Worker(
      new URL('./workers/emergencyWorker.ts', import.meta.url)
    );

    workerRef.current.onmessage = (event) => {
      if (event.data === 'fetchEmergencyLocation') {
        fetchEmergencyLocation();
      }
    };
    workerRef.current.postMessage({ action: 'start' });
  };

  const clearWorkerInterval = () => {
    if (workerRef.current) {
      workerRef.current.postMessage({ action: 'stop' });
      workerRef.current.terminate();
      workerRef.current = null;
    }
  };

  useEffect(() => {
    if (emergency?.status === 'closed') {
      clearWorkerInterval();
    }
  }, [emergency?.status]);

  useEffect(() => {
    if (!isAuthenticated() || !code) return clearWorkerInterval();
    startWorker();
  }, [isAuthenticated(), code]);

  useEffect(() => {
    const handleOffline = () => {
      clearWorkerInterval();
    };

    const handleOnline = () => {
      startWorker();
    };
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [emergency?.status, isAuthenticated(), code]);

  return fetchEmergencyLocation;
};

export default useFetchEmergencyLocation;
