import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './LoadingComponent.module.css';

const LoadingComponent: React.FC = () => {
  return (
    <div className={styles['loading-spinner']}>
      <FontAwesomeIcon icon={faSpinner} spin size="2x" />{' '}
    </div>
  );
};

export default LoadingComponent;
