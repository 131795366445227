import React, { useEffect, useState } from 'react';
import { APIProvider, Map, MapEvent } from '@vis.gl/react-google-maps';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  mapFullScreenClose,
  mapFullScreenOpen,
  mapRegular,
  mapSatellite,
} from '../../services/ampolitudeTracker';
import styles from './MapComponent.module.css';
import ClientRouteMarkers from '../ClientRouteMarkers/ClientRouteMarkers';
import fullScreenIcon from '../../assets/fullscreen-icon.svg';
import exitFullScreenIcon from '../../assets/exis-fullscreen-icon.svg';
import { isIOS } from '../../utils/device';

const MapComponent = () => {
  const { clientLocation, emergency } = useSelector(
    (state: RootState) => state.emergency
  );
  const { user } = useSelector((state: RootState) => state.client);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [lat, lng] =
    clientLocation && emergency?.status === 'open'
      ? clientLocation.split(',')
      : emergency?.startLocation
        ? emergency.startLocation.split(',')
        : [47.040323, 28.85566];

  const handleMapTypeIdChanged = (e: MapEvent) => {
    const mapType = e.map?.getMapTypeId();
    if (mapType === 'hybrid') {
      mapSatellite(user, emergency);
    } else if (mapType === 'roadmap') {
      mapRegular(user, emergency);
    }
  };

  const toggleFullScreen = () => {
    const mapContainer = document.getElementById('main-map');
    if (!isFullScreen) {
      if (mapContainer?.requestFullscreen) {
        mapContainer.requestFullscreen();
      } else if ((mapContainer as any).webkitRequestFullscreen) {
        (mapContainer as any).webkitRequestFullscreen(); // Safari
      } else if ((mapContainer as any).msRequestFullscreen) {
        (mapContainer as any).msRequestFullscreen(); // IE11
      } else {
        mapContainer!.style.position = 'fixed';
        mapContainer!.style.top = '0';
        mapContainer!.style.left = '0';
        mapContainer!.style.width = '100vw';
        mapContainer!.style.height = '100vh';
        mapContainer!.style.zIndex = '9999';
      }
      setIsFullScreen(true);
      mapFullScreenOpen(user, emergency);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen(); // Safari
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen(); // IE11
      } else {
        // iOS fallback: Reset styles
        mapContainer!.style.position = 'relative';
        mapContainer!.style.width = '100%';
        mapContainer!.style.height = '100%';
        mapContainer!.style.zIndex = '0';
      }
      setIsFullScreen(false);
      mapFullScreenClose(user, emergency);
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullscreen = !!document.fullscreenElement;
      if (isFullscreen) {
        setIsFullScreen(isFullscreen);
        mapFullScreenOpen(user, emergency);
      } else {
        setIsFullScreen(isFullscreen);
        mapFullScreenClose(user, emergency);
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      );
    };
  }, [user, emergency]);

  return (
    <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}`}>
      <Map
        defaultCenter={
          lat && lng
            ? { lat: parseFloat(lat), lng: parseFloat(lng) }
            : { lat: 47.040323, lng: 28.85566 }
        }
        defaultZoom={15}
        id="main-map"
        mapId="main-google-map"
        streetViewControl={false}
        fullscreenControl
        onMapTypeIdChanged={handleMapTypeIdChanged}
      >
        {isIOS() && (
          <button
            onClick={toggleFullScreen}
            className={`${styles.fullscreenBtn} ${
              isFullScreen ? styles.exitFullscreen : ''
            }`}
          >
            <img
              src={isFullScreen ? exitFullScreenIcon : fullScreenIcon}
              alt="Fullscreen"
              className={styles.fullscreenIcon}
            />
          </button>
        )}
        <ClientRouteMarkers
          clientLocation={
            emergency?.status === 'open' && clientLocation
              ? clientLocation
              : emergency?.startLocation
          }
          places={user?.places}
        />
      </Map>
    </APIProvider>
  );
};

export default MapComponent;
