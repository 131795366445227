import { useDispatch, useSelector } from 'react-redux';
import {
  fetchClientError,
  fetchClientStart,
  resetClientState,
  setClientData,
} from '../reducers/clientReducer';
import { ClientService } from '../services/client';
import { getCode, isAuthenticated, logout } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import { REDIRECT_PATH } from '../constants/constants';
import { RootState } from '../store/store';
import { resetEmergencyState } from '../reducers/emergencyReducer';
import { logoutForce } from '../services/ampolitudeTracker';
import { toast } from 'react-toastify';

const useFetchClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, user } = useSelector((state: RootState) => state.client);
  const { emergency } = useSelector((state: RootState) => state.emergency);

  const fetchClient = async () => {
    if (loading) return;

    const code = getCode();
    if (!isAuthenticated() || !code) {
      logoutForce(
        user,
        emergency,
        'Is not authenticated or does not have the code saved in local storage'
      );
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      return navigate(REDIRECT_PATH);
    }

    dispatch(fetchClientStart());

    const response = await ClientService.getClient(code);

    if (response.data) {
      dispatch(setClientData(response.data));
    } else if (response.error) {
      dispatch(fetchClientError(response.error.message));
      toast.error(response.error.message);
      logoutForce(user, emergency, response.error.message);
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      navigate(REDIRECT_PATH);
    }
  };

  return fetchClient;
};

export default useFetchClient;
