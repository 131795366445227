export const login = (code: string) => {
  localStorage.setItem('authCode', code);
};

export const logout = () => {
  localStorage.removeItem('authCode');
};

export const getCode = () => {
  return localStorage.getItem('authCode');
};

export const isAuthenticated = () => {
  return Boolean(localStorage.getItem('authCode'));
};
