import React, { useEffect, useState } from 'react';
import styles from './EmergencyDetails.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Photo from '../../utils/photo';

const EmergencyDetails = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.client);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | null>(
    null
  );
  const [currentPhotos, setCurrentPhotos] = useState<string[]>([]);

  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchPosition, setTouchPosition] = useState<number>(0);
  const formattedPhoneNumber =
    user && user.info.emergencyContact ? `+${user.info.emergencyContact}` : '-';

  const handlePhotoClick = (photos: string[], index: number) => {
    setCurrentPhotos(photos);
    setSelectedPhotoIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPhotoIndex(null);
  };

  const showNextPhoto = () => {
    if (selectedPhotoIndex !== null && currentPhotos.length > 1) {
      setSelectedPhotoIndex((selectedPhotoIndex + 1) % currentPhotos.length);
      setTouchPosition(0);
    }
  };

  const showPreviousPhoto = () => {
    if (selectedPhotoIndex !== null && currentPhotos.length > 1) {
      setSelectedPhotoIndex(
        (selectedPhotoIndex - 1 + currentPhotos.length) % currentPhotos.length
      );
      setTouchPosition(0);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartX !== null) {
      const currentTouchX = e.touches[0].clientX;
      setTouchPosition(currentTouchX - touchStartX);
    }
  };

  const handleTouchEnd = () => {
    if (touchPosition > 50) {
      showPreviousPhoto();
    } else if (touchPosition < -50) {
      showNextPhoto();
    } else {
      setTouchPosition(0);
    }
    setTouchStartX(null);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isModalOpen]);

  return (
    <div className={styles['emergency-details-container']}>
      <b>
        <h3>
          {t('emergencyContact')}: {user && user.info.emergencyContactName} (
          <a href={`tel:${formattedPhoneNumber}`}>{formattedPhoneNumber}</a>)
        </h3>
      </b>

      <div className={styles['details-columns']}>
        <div className={styles['details-column']}>
          <h2>{t('myPlaces')}:</h2>
          <ul>
            {user && user.places && user.places.length > 0 ? (
              user.places?.map((place) => (
                <li key={place.id}>
                  <div>
                    <b>
                      <h3>
                        {place.name}: {place.streetAddress},{' '}
                        {place.streetNumber}, {place.region}
                      </h3>
                    </b>
                    <p>
                      <b>
                        {t('apartment')}: {place.apartment}, {t('floor')}:{' '}
                        {place.floor}, {t('intercomCode')}: {place.intercomCode}
                      </b>
                    </p>
                    <div className={styles['photo-gallery']}>
                      {place.photos?.map((photoId: string, index: number) => (
                        <Photo
                          key={index}
                          photoId={photoId}
                          alt={`${place.name} photo ${index + 1}`}
                          onClick={() => handlePhotoClick(place.photos, index)}
                          className={styles['thumbnail']}
                        />
                      ))}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li>{t('noPlaces')}</li>
            )}
          </ul>
        </div>

        <div className={styles['details-column']}>
          <h2 className={styles['emergency-title']}>{t('myCars')}:</h2>
          <ul>
            {user && user.cars && user.cars.length > 0 ? (
              user.cars?.map((car) => (
                <li key={car.id}>
                  <div>
                    <b>
                      <h3>{car.license}</h3>
                    </b>
                    <p>
                      <b>
                        {t('brand')}: {car.brand}, {t('color')}: {car.color}
                      </b>
                    </p>
                    <p>
                      <b>{car.model}</b>
                    </p>
                    <div className={styles['photo-gallery']}>
                      {car.photos?.map((photoId: string, index: number) => (
                        <Photo
                          key={index}
                          photoId={photoId}
                          alt={`${car.brand} ${car.model} photo ${index + 1}`}
                          onClick={() => handlePhotoClick(car.photos, index)}
                          className={styles['thumbnail']}
                        />
                      ))}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li>{t('noCars')}</li>
            )}
          </ul>
        </div>
      </div>

      {isModalOpen && selectedPhotoIndex !== null && (
        <div className={styles['modal-overlay']} onClick={closeModal}>
          <div
            className={styles['modal-content']}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              transform: `translateX(${touchPosition}px)`,
              transition: touchPosition === 0 ? 'transform 0.3s ease' : 'none',
            }}
          >
            <button onClick={closeModal} className={styles['exit-button']}>
              ✕
            </button>
            <button
              onClick={showPreviousPhoto}
              className={`${styles['modal-nav-button']} ${styles['left-button']}`}
            >
              ‹
            </button>
            <Photo
              photoId={currentPhotos[selectedPhotoIndex]}
              alt="Full-size"
              className={styles['modal-image']}
            />
            <button
              onClick={showNextPhoto}
              className={`${styles['modal-nav-button']} ${styles['right-button']}`}
            >
              ›
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmergencyDetails;
