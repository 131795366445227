import { combineReducers } from 'redux';
import client from './clientReducer';
import emergency from './emergencyReducer';

const rootReducer = combineReducers({
  client,
  emergency,
});

export default rootReducer;
