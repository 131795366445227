import axios, { AxiosError, AxiosResponse } from 'axios';
import i18n from '../translation/i18n';

/**
 * Interface for an API response.
 */
export interface ApiResponse<T> {
  data?: T;
  error?: ErrorResponse;
}

/**
 * Interface for an error response.
 */
interface ErrorResponse {
  message: string;
  code: string;
  status?: number;
}

/**
 * Generic error handler for API requests using axios.
 * @param {() => Promise<AxiosResponse<T>>} requestFunction - The function that performs the API request.
 * @returns {Promise<ApiResponse<T>>} - Returns a promise that resolves with the response data if successful, or an error object if failed.
 */
export const handleRequest = async <T>(
  requestFunction: () => Promise<AxiosResponse<T>>
): Promise<ApiResponse<T>> => {
  try {
    const response = await requestFunction();
    return { data: response.data };
  } catch (error) {
    return { error: handleError(error) };
  }
};

/**
 * Function to handle different types of errors.
 * @param {unknown} error - Error object thrown by failed request or code execution.
 * @returns {ErrorResponse} - An error response object with a message and code.
 */
const handleError = (
  error: unknown
): { code: string; message: string; status?: number } => {
  console.error('Error:', error);

  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<{ message: string }>;

    if (!axiosError.response) {
      return {
        message: i18n.t('networkErrorMessage'),
        code: 'NETWORK_ERROR',
      };
    }

    if (axiosError.response.status >= 500) {
      return {
        message: i18n.t('serverErrorMessage'),
        code: 'SERVER_ERROR',
      };
    }

    if (axiosError.response.status >= 400 && axiosError.response.status < 500) {
      return {
        message: i18n.t('clientErrorMessage'),
        // axiosError.response.data?.message ||
        status: axiosError.response.status,
        code: 'CLIENT_ERROR',
      };
    }
  }

  return {
    message: (error as Error).message || i18n.t('unknownErrorMessage'),
    code: 'UNKNOWN_ERROR',
  };
};
