import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Emergency, EmergencyLocation, EmergencyState } from '../types/types';

const initialState: EmergencyState = {
  emergency: null,
  clientLocation: null,
  loading: false,
  error: null,
};

const emergencySlice = createSlice({
  name: 'emergency',
  initialState,
  reducers: {
    fetchEmergencyStart(state) {
      state.loading = true;
      state.error = null;
    },

    setEmergencyData(state, action: PayloadAction<Emergency>) {
      state.emergency = action.payload;
      state.loading = false;
    },

    updateEmergencyLocation(state, action: PayloadAction<EmergencyLocation>) {
      if (state.emergency) {
        state.clientLocation = action.payload.location;
      }
    },

    fetchEmergencyError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    resetEmergencyState: () => initialState,
  },
});

export const {
  fetchEmergencyStart,
  setEmergencyData,
  updateEmergencyLocation,
  fetchEmergencyError,
  resetEmergencyState,
} = emergencySlice.actions;

export default emergencySlice.reducer;
