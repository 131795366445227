import React, { useState } from 'react';
import styles from './ClientDetails.module.css';
import { useTranslation } from 'react-i18next';
import LogoutComponent from '../Logout/LogoutComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import LoadingComponent from '../Loading/LoadingComponent';
import Photo from '../../utils/photo';

const ClientDetails = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.client);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePhotoClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!user) {
    return <LoadingComponent />;
  }

  const formattedPhoneNumber = `+${user.phone || '-'}`;

  const age = user.info?.birthdate
    ? new Date().getFullYear() - new Date(user.info.birthdate).getFullYear()
    : '-';

  return (
    <div className={styles['client-details-container']}>
      <LogoutComponent />
      <h2>{t('clientDetailsTitle')}</h2>
      <Photo
        photoId={user?.info?.photo?.uuid}
        alt={t('clientPhotoAlt')}
        onClick={() => handlePhotoClick()}
        className={styles['client-photo-thumbnail']}
      />
      <div className={styles['client-details-text']}>
        <p>
          {t('name')}:{' '}
          <b>
            {user.info.firstName ? user.info.firstName + ' ' : ''}{' '}
            {user.info.midName ? user.info.midName + ' ' : ''}
            {user.info.lastName ? user.info.lastName + ' ' : ''}
          </b>
        </p>
        <p>
          {t('phone')}:{' '}
          <b>
            <a href={`tel:${formattedPhoneNumber}`}>{formattedPhoneNumber}</a>
          </b>
        </p>
        <p>
          {t('age')}:{' '}
          <b>
            {age} {t('yearsOld')}
          </b>
        </p>
        <p>
          {t('height')}:{' '}
          <b>
            {user.info.height ? user.info.height : '-'} {t('heightUnit')}
          </b>
        </p>
        <p>
          {t('weight')}:{' '}
          <b>
            {user.info.weight ? user.info.weight : '-'} {t('weightUnit')}
          </b>
        </p>
        <p>
          {t('sex')}: <b>{user.info.sex ? t(user.info.sex) : '-'} </b>
        </p>
      </div>

      {isModalOpen && (
        <div className={styles['modal-overlay-client']} onClick={closeModal}>
          <div className={styles['modal-content-client']}>
            <Photo
              photoId={user?.info?.photo?.uuid}
              alt={t('clientPhotoAlt')}
              className={styles['client-photo-fullscreen']}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDetails;
