import axios from 'axios';
import { useEffect, useState } from 'react';

const fileCache: Record<string, string> = {};

const getFile = async (url: string) => {
  try {
    const file = await axios.get(url, { responseType: 'blob' });
    return file.data;
  } catch (e: any) {
    return null;
  }
};

export const useFile = <T>(url: string | undefined, updateIf: T[] = []) => {
  const [file, handleFile] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!url || url.includes('undefined') || url.includes('null')) {
      handleFile(undefined);
      return;
    }

    if (fileCache[url]) {
      handleFile(fileCache[url]);
      return;
    }

    getFile(url).then((data) => {
      if (data) {
        const blobUrl = URL.createObjectURL(data);
        fileCache[url] = blobUrl;
        handleFile(blobUrl);
      } else {
        handleFile(undefined);
      }
    });
  }, [url, ...updateIf]);

  return file;
};
