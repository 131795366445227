import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSelector.module.css';
import {
  languageChangeEn,
  languageChangeRo,
  languageChangeRu,
} from '../../services/ampolitudeTracker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const { user } = useSelector((state: RootState) => state.client);
  const { emergency } = useSelector((state: RootState) => state.emergency);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };
  return (
    <div className={styles['language-selector']}>
      <span
        className={i18n.language === 'ro' ? styles['active'] : ''}
        onClick={() => {
          languageChangeRo(user, emergency);
          handleLanguageChange('ro');
        }}
      >
        RO
      </span>
      <span
        className={i18n.language === 'ru' ? styles['active'] : ''}
        onClick={() => {
          languageChangeRu(user, emergency);
          handleLanguageChange('ru');
        }}
      >
        RU
      </span>
      <span
        className={i18n.language === 'en' ? styles['active'] : ''}
        onClick={() => {
          languageChangeEn(user, emergency);
          handleLanguageChange('en');
        }}
      >
        EN
      </span>
    </div>
  );
};

export default LanguageSelector;
